<template>
  <div>
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <v-card v-else flat class="px-1 mt-1">
      <v-card-text class="pa-0">
        <v-row class="mb-2 mx-0">
          <v-col cols="6" class="pa-0 pr-1 pt-1">
            <v-text-field append-icon="mdi-magnify" :label="$t('message.common.search')" hide-details  single-line outlined dense v-model="search"></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" class="pa-0 pl-1 pt-1">
            <v-select outlined dense :items="projectListTypes" hide-details v-model="projectListType" item-text="text" item-value="value"></v-select>
          </v-col>
        </v-row>
        <!-- <div class="text-center">
          <v-progress-circular margin="m-auto" v-if="loading" :size="50" :width="5" color="purple" indeterminate></v-progress-circular>
        </div> -->
        <compact-list :payload="payload" :key="reInit"></compact-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      search: '',
      projectListType: 1,
      loading: false,
      payload: {
        module: '',
        moduleTitle: 'message.layout.projects',
        pagination: {},
        totalCount: 0,
        loading: false,
        editHandler: (item) => this.$router.push(`/projects/${item.id}`),
        list: [],
        completeList: [],
        items: [{
          value: 'number', // po_ranked
          class: 'pa-0 pl-2 pb-1',
          cols: '3',
          spanClass: 'font-weight-bold',
          projectColor: true,
          colorValue: 'color'
        }, {
          value: 'name', // ao_ranked
          class: 'pa-0 pl-2 pb-1',
          cols: '9'
        }, {
          value: 'po_name', // project_name
          class: 'pa-0 pl-2 pb-1',
          cols: '6'
        }, {
          value: 'ao_name', // work_order_name
          class: 'pa-0 pl-2 pb-1',
          cols: '6'
        }, {
          value: 'customer_name',
          class: 'pa-0 pl-2 pb-1',
          cols: '6'
        }, {
          value: `${this.$i18n.locale}_statename`,
          class: 'pa-0 pl-2 pb-1',
          cols: '6'
        }, {
          value: 'description',
          class: 'pa-0 pl-2 pr-1 text-justify',
          cols: '12',
          isParagraph: true
        }],
        storeIndex: 'PROJECT_LIST_INDEX',
        hasNoNext: true
      },
      reInit: 0,
      projectsCloneList: [],
      awaitingSearch: false,
      role: this.$formatter.cloneVariable(this.$store.state.auth.role),
      fieldsLoading: false
    }
  },
  created () {
    this.fieldsLoading = true
    this.$store.dispatch('getGeneralSettings').then(() => {
      setTimeout(() => {
        if (this.$store.state.common.isProjectExcluded) this.$router.push('/')
        else this.getFilteredProjects()
      }, 0)
    })
    // if (this.$store.state.common.isProjectExcluded) this.$router.push('/')
  },
  components: {
    'compact-list': () => import('@/components/CompactList.vue')
  },
  computed: {
    filteredList () {
      return this.payload.completeList.filter(
        x =>
          (x.number ? x.number.toString().includes(this.search) : '') ||
          (x.ao_name ? x.ao_name.toString().includes(this.search.toLowerCase()) : '') ||
          (x.po_name ? x.po_name.toString().includes(this.search.toLowerCase()) : '') ||
          x.name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    projectListTypes () {
      const projectFilterTypes = [
        { text: this.$t('message.projects.all'), value: 4 },
        { text: this.$t('message.projects.allActive'), value: 3 },
        { text: this.$t('message.projects.myAll'), value: 2 },
        { text: this.$t('message.projects.myActive'), value: 1 }
      ]
      if (!['admin', 'superadmin'].includes(this.role.slug)) projectFilterTypes.splice(0, 2)
      return projectFilterTypes
    }
  },
  methods: {
    getFilteredProjects () {
      /* this.loading = true */
      this.payload.loading = true
      this.$api.execute('get', `projects/get_as_simplified_for_pwa?filter=${this.projectListType}`)
        .then(response => {
          const projectsList = this.$formatter.cloneVariable(response.data)
          const timerProject = projectsList.find(x => x.number === 'TM001')
          if (timerProject) {
            const indexOf = projectsList.indexOf(timerProject)
            projectsList.splice(indexOf, 1)
          }
          this.projectsCloneList = this.$formatter.cloneVariable(projectsList)
          this.fieldsLoading = false
          setTimeout(() => {
            this.setVisibleProjects()
          })
        })
        .finally(() => {
          this.payload.loading = false
        })
    },
    getProjectStatusName (project) {
      const statusName = project[`${this.$i18n.locale}_statename`]
      return statusName || ''
    },
    setVisibleProjects () {
      let visibleProjects = this.projectsCloneList.filter(
        x =>
          (x.number ? x.number.toString().toLowerCase().includes(this.search) : '') ||
          (x.ao_name ? x.ao_name.toString().toLowerCase().includes(this.search.toLowerCase()) : '') ||
          (x.po_name ? x.po_name.toString().toLowerCase().includes(this.search.toLowerCase()) : '') ||
          (x.name ? x.name.toString().toLowerCase().includes(this.search.toLowerCase()) : '') ||
          (x.customer_name ? x.customer_name.toLowerCase().includes(this.search.toLowerCase()) : '')
      )
      visibleProjects = visibleProjects.sort((a, b) => (+(a.number.replace(/[^0-9]/g, '')) > +(b.number.replace(/[^0-9]/g, '')) ? 1 : -1))
      this.payload.completeList = visibleProjects
      this.payload.total = visibleProjects && visibleProjects.length ? visibleProjects.length : 0
      setTimeout(() => {
        this.$eventBus.$emit('changePage', 1)
        this.reInit++
      }, 50)
    }
  },
  watch: {
    search (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.setVisibleProjects()
          this.awaitingSearch = false
        }, 500) // .5 sec delay
      }
      this.awaitingSearch = true
    },
    projectListType () {
      this.getFilteredProjects()
    }
  }
}
</script>
<style lang="css" scoped>
.custom_model_close {
  position: absolute;
  top: 1px;
  right: 3px;
}
</style>
